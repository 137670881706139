import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
// import colors from 'constants/theme';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
  margin-bottom: 8rem;
  width: 100%;
  ${MEDIA.DESKTOP`
    margin-bottom: 4rem;
  `}
  ${MEDIA.TABLET`
    display: block;
  `};
  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      p {
        color: black;
      }
    }
  }
`;
