import React from 'react';
import PropTypes from 'prop-types';
// import Img from 'gatsby-image';
import {
  ItemWrapper,
  Title,
  Copy,
  ButtonContainer,
  LinkText,
  StyledLink,
  StyledArrow,
  StyledImg,
  Figure,
} from './item.css';

const Item = ({ title, image, copy }) => (
  <ItemWrapper>
    <Figure>
      <StyledImg fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
      <Figure>
        <Title>{title}</Title>
        <Copy>{copy}</Copy>
        <ButtonContainer>
          <StyledLink to="/services" aria-label={title}>
            <LinkText>Learn more</LinkText>
            <StyledArrow />
          </StyledLink>
        </ButtonContainer>
      </Figure>
    </Figure>
  </ItemWrapper>
);

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired,
};

export default Item;
