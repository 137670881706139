import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Intro from 'components/intro';
import Services from 'components/services';
import Testimonials from 'components/testimonials';
import Footer from 'components/footer';
import ContactUs from 'components/contactUs';

const Index = ({ data }) => {
  const backgroundFluidImageStack = [
    data.homeJson.background.image.childImageSharp.fluid,
    'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%)',
  ].reverse();

  return (
    <Layout id="page-wrap">
      <Box>
        <BackgroundImage
          Tag="section"
          className="header"
          fluid={backgroundFluidImageStack}
        >
          <Intro
            header="Master Thatching Services"
            content={data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
            align="right"
          />
        </BackgroundImage>
      </Box>
      <Services
        items={data.homeJson.gallery}
        content={data.homeJson.welcomeText.childMarkdownRemark.rawMarkdownBody}
      />
      <Testimonials
        items={data.homeJson.testimonials}
        background={
          data.homeJson.testimonialBackground.image.childImageSharp.fluid
        }
      />
      <ContactUs />
      <Footer logo={data.homeJson.whiteLogo.image} />
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      background {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      logo {
        image {
          childImageSharp {
            fixed(width: 130, height: 130) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      whiteLogo {
        image {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      welcomeText {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      testimonials {
        author
        text
      }
      testimonialBackground {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
