import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Text, TitleContainer } from './services.css';
import PageContainer from 'components/pageContainer';
import ServicesLayout from 'components/servicesLayout';
import SectionHeader from '../sectionHeader/sectionHeader';

const Services = ({ items, content }) => (
  <Wrapper>
    <TitleContainer>
      <SectionHeader>The services we offer</SectionHeader>
      <Text>{content}</Text>
    </TitleContainer>
    <PageContainer>
      <ServicesLayout items={items} />
    </PageContainer>
  </Wrapper>
);

Services.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  content: PropTypes.string.isRequired,
};

export default Services;
