import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
// import colors from 'constants/theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .background {
    width: 100%;
    height: 480px;
    ${MEDIA.DESKTOP`
      height: 550px;
  `};
  }
  .carousel {
    height: 100%;
    overflow: hidden;
    .slick-prev {
      left: 5px;
    }
    .slick-next {
      right: 5px;
    }
    .slick-list {
      height: 100%;
    }
    .slick-track {
      display: flex !important;
      height: 100%;
    }
    .slick-slide {
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .slick-dots {
      bottom: 10px;
      button {
        &:before {
          color: #fff;
          font-size: 24px;
          opacity: 0.75;
        }
      }
      .slick-active {
        button {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5em;
  width: 100%;
  max-width: 960px;
  padding: 0 4em;
  box-sizing: border-box;
`;

export const Line = styled.div`
  display: flex;
  flex: 1;
  height: 2px;
  background: rgba(51, 51, 51, 0.3);
`;

export const Title = styled.h1`
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  color: rgb(51, 51, 51);
`;

export const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Container = styled.div`
  background: rgba(255, 255, 255, 1);
  padding: 3em;
  border-radius: 0.5em;
  transition: all 0.5s ease-out;
  width: 70%;
  margin: 0 auto;
  ${MEDIA.DESKTOP`
    width: 65%;
    padding: 1em 1.5em;
  `};
`;

export const Text = styled.p`
  line-height: 1.6em;
  ${MEDIA.DESKTOP`
  font-size: 0.8em;
  `};
`;

export const Author = styled.p`
  margin-bottom: 0.5em;
  font-weight: 600;
`;
