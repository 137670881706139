import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import colors from 'constants/theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4em 2em;
  /* max-width: 960px; */
  ${MEDIA.DESKTOP`
    padding: 3em 2em 0; 
  `};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em 0;
  width: 100%;
  max-width: 960px;
`;

export const Line = styled.div`
  display: flex;
  flex: 1;
  height: 2px;
  background: ${colors.color5};
`;

export const Title = styled.h2`
  font-family: 'Rokkit', sans-serif;
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  color: rgb(51, 51, 51);
`;

export const Text = styled.p`
  line-height: 1.5;
  margin: 3em 0 1em;
  text-align: center;
`;

export const ServiceContainer = styled.div``;
