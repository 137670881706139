import styled from 'styled-components';
import { Link } from 'gatsby';
// import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import MEDIA from 'helpers/mediaTemplates';
import colors from 'constants/theme';
import Button from '../../contactBtn';
import NextIcon from '../../../../content/images/next.svg';

export const ItemWrapper = styled.div`
  background: white;
  transition: all 0.1s ease-in-out;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  ${MEDIA.DESKTOP`
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);  
    margin-bottom: 6em;
  `}
`;

export const Figure = styled.figure`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
`;

export const Title = styled.h3`
  color: #222;
  display: block;
  font-weight: 600;
  font-size: 1.1em;
  text-align: center;
  margin: 1.5em 0 1em;
`;

export const Copy = styled.p`
  text-align: center;
  line-height: 1.4;
  margin-bottom: 0em;
  color: ${colors.greyText};
  font-weight: 600;
  font-size: 0.9em;
  padding: 0 2em;
  margin-bottom: 1em;
  ${MEDIA.TABLET`
    margin-bottom: 2rem;
  `};
`;

export const StyledButton = styled(Button)`
  margin: 1em auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: auto; */
  margin-bottom: 2em;
  /* margin: 1em 0 1.5em; */
`;

export const StyledArrow = styled(NextIcon)`
  position: absolute;
  right: -10px;
  transition: 0.1s ease;
`;

export const LinkText = styled.p`
  font-size: 0.8em;
  font-weight: 600;
  color: ${colors.blackText};
  margin-bottom: 0;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  &:hover {
    ${LinkText} {
      color: ${colors.color1};
    }
    ${StyledArrow} {
      right: -13px;
      path {
        stroke: ${colors.color1};
      }
    }
  }
`;

export const StyledImg = styled(BackgroundImage)`
  /* height: 20em; */
  padding-top: 75%;
  width: 100%;
`;
