import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import Slider from 'react-slick';
// import IO from 'components/io';
import {
  Wrapper,
  Container,
  Text,
  Author,
  TitleContainer,
  Slide,
} from './testimonials.css';
import SectionHeader from '../sectionHeader/sectionHeader';

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: 'carousel',
  autoplay: true,
  speed: 500,
  autoplaySpeed: 10000,
};

const Testimonials = ({ items, background }) => {
  const backgroundFluidImageStack = [
    background,
    'linear-gradient(rgba(0,0,0, 0.3), rgba(0, 0, 0, 0.3))',
  ].reverse();

  return (
    <Wrapper>
      <TitleContainer>
        <SectionHeader>What people say about us</SectionHeader>
      </TitleContainer>
      <BackgroundImage
        Tag="section"
        className="background"
        fluid={backgroundFluidImageStack}
      >
        <Slider {...settings}>
          {items.map(item => (
            <Slide key={item.author}>
              <Container>
                <Text>{`"${item.text}"`}</Text>
                <Author>{item.author}</Author>
              </Container>
            </Slide>
          ))}
        </Slider>
      </BackgroundImage>
    </Wrapper>
  );
};

Testimonials.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  background: PropTypes.object,
};

export default Testimonials;
